<template>
  <v-app>
    <div class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden">
      <div class="d-flex flex-column-fluid flex-center">
          <div class="d-flex flex-column " v-if="loading">
            <v-skeleton-loader
                    v-bind="attrs"
                    width="500"
                    type="list-item-three-line"

            ></v-skeleton-loader>
          </div>
        <div class="d-flex flex-column " v-if="!not_valid && !loading">
          <template v-if="!payment">
              <h1 class="mb-5">Application submitted</h1>
              <div class="col-md-12 text-center">
                  <img src="/media/payments/success.png" style="width: 150px; text-align: center"/>
              </div>
              <p>Your application has been submitted. Please make sure to pay the admission fee at the college premise
              to confirm your application.</p>
              <v-btn @click.prevent="newPreRegistration()" class="text-white btn btn-primary font-size-base" depressed>
                New registration
              </v-btn>
          </template>
          <template v-else>
            <v-row class="">
                <v-col cols="12" class="mt-20 background-color-border" v-if="khaltiError">
                    <span class="text-danger" v-if="khaltiError && khaltiError.customer_info && khaltiError.customer_info.phone">{{khaltiError.customer_info.phone[0]}}</span>
                    <span class="text-danger" v-if="khaltiError && khaltiError.return_url && khaltiError.return_url">{{khaltiError.return_url[0]}}</span>
                    <span class="text-danger" v-if="khaltiError && khaltiError.website_url && khaltiError.website_url">{{khaltiError.website_url[0]}}</span>
                    <span class="text-danger" v-if="khaltiError && khaltiError.amount && khaltiError.amount">{{khaltiError.amount[0]}}</span>
                    <span class="text-danger" v-if="khaltiError && khaltiError.purchase_order_id && khaltiError.purchase_order_id">{{khaltiError.purchase_order_id[0]}}</span>
                    <span class="text-danger" v-if="khaltiError && khaltiError.purchase_order_name && khaltiError.purchase_order_name">{{khaltiError.purchase_order_name[0]}}</span>
                    <span class="text-danger" v-if="khaltiError && khaltiError.detail && khaltiError.detail">{{khaltiError.detail}}</span>
                </v-col>
                <v-col cols="12" class="mt-20 background-color-border">
                    <h1 class="mb-5">Finalise your application</h1>
                    <p>To submit your application, you will need to select a payment option. You can either pay at counter or pay online usine eSewa.</p>
                    <h1 class=" text-red">You total amount due for today is</h1>
                    <h1 class="text-red">Rs.{{ admission_fee }}.00</h1>

                </v-col>
              <v-col cols="12" class="text-left ">
                  <h1>Choose your payment option</h1>
                  <a v-if="admission_fee > 0"  @click.prevent="payAtCollege()" class="payment-img"><img src="/media/payments/cash_at_college.jpg" /></a>
                  <a v-if="payment_status && admission_fee > 0" @click.prevent="payWithEsewa()" class="payment-img"><img src="/media/payments/esewa_logo.jpg" /></a>
                  <a v-if="khaltiStatus && admission_fee > 0" id="payment-button" class="payment-img" @click.prevent="payWithKhalti()"><img src="/media/payments/khalti.png" height="62"/></a>
             </v-col>
                <v-col>
                    <h5 class="">Note</h5>
                    <ul>
                        <li> For cash/offline payment, you can pay the due amount at our college premise. Your admit card will be issued after the payment is received. </li>
                        <li> For online payment, you can pay using Esewa account. Your admit card will be sent to your email after successful payment. </li>
                    </ul>
                </v-col>
            </v-row>
          </template>
        </div>
        <div class="d-flex flex-column align-items-center align-center " v-if="not_valid && !loading">
          <h1 class="mb-5">Not a valid request</h1>
        </div>
      </div>
    </div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import {ADMISSION_URL, API_URL} from "@/core/config";
import EssentialService from "@/core/services/enrollment/EssentialService";
import OptionService from "@/core/services/option/OptionService";

const option=new OptionService();
const essentialService = new EssentialService();
export default {
  name: "Success",
  data() {
    return {
      attrs: [],
      dialog: false,
      payment: true,
      overlay: false,
      not_valid: false,
      admission_fee:0,
      product_code:null,
      payment_gateway:null,
      khaltiPaymentGateway:null,
      payment_status:0,
      loading: true,
      app_env: process.env.VUE_APP_ENV,
      esewa_stage_active:0,
      pidx: null,
      khaltiStatus:0,
      khaltiError: null
    }
  },
  mounted() {
    if(this.$route.query.pidx) {
      this.verifyKhaltiPayment();
    }
    if(this.$route.query.message) {
      this.$router.push({name: "errror"})
    }

    if (localStorage.hasOwnProperty('_newToken')) {
      this.payment = true;
      this.getAdmissionFee();
      this.getUniqueProductCode();
      this.getPaymentDetailInformation();
      this.getByAppUrl();
      this.getKhaltiInformation();
    }else{
     this.payment = false
      this.not_valid = true;
      this.$snotify.warning("You will be redirected to main page in 5 seconds")
      setTimeout(() => {
        this.$router.push({name: 'index'})
      }, 5000);
    }
  },
  methods: {
    verifyKhaltiPayment() {
      if (localStorage.hasOwnProperty('_newToken') && this.$route.query.pidx) {
        let id = localStorage.getItem('_newToken');
        this.loading = true;

        essentialService
            .verifyKhaltiPayment(id, {pidx: this.$route.query.pidx})
            .then(response => {
              window.location.href = response.data.url;
              this.loading = false;
            }).catch(() => {
          this.loading = false;
        });
      }
    },
   getPaymentDetailInformation(){
       if (localStorage.hasOwnProperty('_newToken')) {
           essentialService
               .getPaymentDetailInformation('esewa')
               .then(response => {
                  this.payment_gateway=response.data.setting;
                  this.payment_status=response.data.payment_status;
               })
               .catch(error => {

               })
               .finally(() => {
                   this.loading = false;
               })
       }
     },
   getKhaltiInformation(){
       if (localStorage.hasOwnProperty('_newToken')) {
           essentialService
               .getPaymentDetailInformation('khalti')
               .then(response => {
                  this.khaltiPaymentGateway = response.data.setting;
                  this.khaltiStatus = response.data.payment_status;
               })
               .catch(error => {
                 this.loading = false;
               })
       }
     },
    getAdmissionFee(){
      if (localStorage.hasOwnProperty('_newToken')) {
       let id=localStorage.getItem('_newToken');
       if(id){
         essentialService
             .getAdmissionFee(id)
             .then(response => {
               this.admission_fee=response.data.admission_fee;
             })
             .catch(error => {
               this.$snotify.error("Something went wrong. Please try again later");
             })
       }
      }
    },

    getUniqueProductCode(){
       if (localStorage.hasOwnProperty('_newToken')) {
              let id=localStorage.getItem('_newToken');
              if(id){
                essentialService
                    .getUniqueProductCode(id)
                    .then(response => {
                      this.product_code=response.data.product_code;
                    })
                    .catch(error => {
                      this.$snotify.error("Something went wrong. Please try again later");
                    })
              }
        }
    },

    newPreRegistration() {
      this.$store.commit("setEnrollment", {})
      this.$router.push({name: 'pre-registration'});
      // window.location.href = '/pre-registration';
    },

    payAtCollege() {
      if (localStorage.hasOwnProperty('_newToken')) {
        let id=localStorage.getItem('_newToken');
        this.$confirm({
          message: `You have selected to pay at college. Your admit card will be issued once a payment is received. Please make sure to make payment to secure your entrance date and time.`,
          button: {
            no: "Cancel",
            yes: "Submit application"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              this.overlay = true
              let data = {amt: this.admission_fee};
              essentialService
                  .payAtCollege(id,data)
                  .then(response => {

                    this.$snotify.success("Thank you for submitting your application")
                    this.$router.push({name: 'success'});
                    if (localStorage.hasOwnProperty('_newToken')) {
                      localStorage.removeItem('_newToken');
                    }
                    this.overlay = false
                    this.payment = false
                  // this.$store.commit("setEnrollment", {})
               }).catch(error => {
                this.overlay = false
                this.$snotify.error("Something went wrong. Please try again later");
              })
            }
          }
        });
      }else{
        this.$snotify.error("Something went wrong.");
      }
    },

    payWithEsewa(){
      if (localStorage.hasOwnProperty('_newToken')) {
          if(this.payment_status){
              let id=localStorage.getItem('_newToken');
              if(id){
                let verification_success_url =''
                let verification_fail_url = ''
                let path = 'https://uat.esewa.com.np/epay/main';

                if(this.esewa_stage_active=='1' || this.esewa_stage_active==1){

                  verification_success_url = API_URL + `api/admission/enrollment/${id}/esewa-verification?q=su`;
                  verification_fail_url = ADMISSION_URL + `#/error`;
                }
                else{
                  path = process.env.VUE_APP_ESEWA_PAYMENT_URL;
                  verification_success_url = API_URL + `api/admission/enrollment/${id}/esewa-verification?q=su`;
                  verification_fail_url = ADMISSION_URL + `#/error`;

                }
                let productCode=null;
                if(this.product_code){
                  productCode=this.product_code;
                }else{
                  productCode ='ONLINE_ADMISSION_FEE'+'_'+id;
                }
                let merchant_id=null;
                if(this.payment_gateway && this.payment_gateway.merchant_id){
                  merchant_id=this.payment_gateway.merchant_id;
                }else{
                  merchant_id=process.env.VUE_APP_ESEWA_MERCHANT;
                }
                var params = {
                  amt: this.admission_fee,
                  psc: 0,
                  pdc: 0,
                  txAmt: 0,
                  tAmt: this.admission_fee,
                  pid: productCode,
                  scd: merchant_id,
                  su:  verification_success_url,
                  fu: verification_fail_url
                }

                this.esewaPost(path, params)
              }


          }

      }
    },

    payWithKhalti(){
      if (localStorage.hasOwnProperty('_newToken')) {
        if(this.khaltiPaymentGateway){
          let id = localStorage.getItem('_newToken');
          let return_url = ADMISSION_URL + `#/success`;
          let website_url = window.location.origin;

          let config = {
            "purchase_order_id": "ONLINE_ADMISSION_FEE - "+id,
            "purchase_order_name": "ONLINE_ADMISSION_FEE - "+id,
            "return_url": return_url,
            "website_url": website_url,
            "amount": parseInt(this.admission_fee) * 100,
          };

          essentialService
              .initiateEpayment(id, config)
              .then(response => {
                if(response.data.status == "OK" && response.data.khalti && response.data.khalti.payment_url) {
                  this.pidx = response.data.khalti.pidx;
                  window.location.href = response.data.khalti.payment_url;
                } else {
                  this.khaltiError = response.data.khalti
                }
              }).catch((error) => {
                this.$snotify("Oops something went wrong");
              });
        }
      }

    },

    esewaPost(path, params) {
      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);

      for(var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      form.submit();
    },

    getByAppUrl(){
          option
              .getByKey('ESEWA_STAGE_ACTIVE')
              .then(response => {
                  if(response.data){
                      this.esewa_stage_active = response.data.value;
                  }
              }).catch((err) => {
          }).finally(() => {


          });
      },
  }
}
</script>
